import { Container } from "@mui/material"
import ReactMarkdown from "react-markdown"

export function TermsAndConditions() {


    const termsMarkdown = `
## Terms of Service

**Effective Date:** 03.01.2024

Welcome to PitwallCompanion ("the Web App"), provided by Oliver Traub ("we," "our," or "us"). These Terms of Service ("Terms") govern your access to and use of our Web App. By using the Web App, you agree to these Terms. If you do not agree, please do not use the Web App.

---

#### 1. Acceptance of Terms

By accessing or using our Web App, you agree to comply with these Terms. Continued use constitutes acceptance of any updates to the Terms, which we may modify from time to time.

---

#### 2. User Responsibilities

You agree to:

- Provide accurate and complete information when registering.
- Keep your login credentials secure and confidential.
- Use the Web App only for lawful purposes.
- Not upload or share illegal, harmful, or unauthorized content.

---

#### 3. Account Management

Users are required to create an account to use certain features. You are responsible for:

- Maintaining the security of your account.
- Notifying us immediately of unauthorized access or breach.
- Ensuring your account information is current.

We reserve the right to suspend or terminate accounts violating these Terms.

---

#### 4. Uploaded Data

By uploading data, you:

- Warrant that you own or have the right to share the data.
- Grant us a license to process your data solely for service delivery.
- Agree not to upload harmful or inappropriate content.

---

#### 5. Intellectual Property

All intellectual property rights in the Web App, its design, and its content (excluding user-uploaded data) belong to us. Some assets within the Web App are sourced from third parties, and their respective intellectual property rights remain with their original owners. Users retain ownership of their uploaded data.

---

#### 6. Disclaimers and Limitations of Liability

We provide the Web App "as is" without warranties of any kind. We are not liable for:

- Data loss.
- Service interruptions.
- Unauthorized access to user accounts due to user negligence.

---

#### 7. Termination of Service

We may suspend or terminate your account if you violate these Terms. Users may also terminate their accounts by contacting us at contact@pitwallcompanion.com.

---

#### 8. Governing Law

These Terms are governed by the laws of Germany. Any disputes will be resolved in Nuremberg, Bavaria.

---

#### 9. Contact Information

For questions about these Terms, please contact us at contact@pitwallcompanion.com.
    `

    return (
        <Container>
            <ReactMarkdown>{termsMarkdown}</ReactMarkdown>
        </Container>
    )
}