import { Container, Typography, Button, Table, TableContainer, Paper, TableBody, TableRow, TableCell, TableHead } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { supabaseClient } from "../shared/supabase-client";

export function SearchComponent() {
    const { searchString } = useParams();

    const [searchResults, setSearchResults] = useState<{
        username: string,
        avatar_url: string,
        id: string
    }[]>([]);




    useEffect(() => {
        document.title = "Pitwall Companion - Search";
        const search = () => {
            supabaseClient.from("profiles").select("username,id,avatar_url").ilike("username", searchString+"%").then(({ data, error }) => {
                if (error) {
                    console.error("Error searching for users", error);
                    return;
                }
    
                const usernames = data ?? [];
                setSearchResults(usernames);
            });
        };
        search();
    }, [searchString]);


    if (searchString === undefined) {
        return (
            <Container>
                <Typography variant="h3">Search</Typography>
                <Typography variant="h5">Please enter a search term in the URL</Typography>
            </Container>
        );
    }



    return (
        <Container>
            <Typography variant="h3">Search Results</Typography>
            <SearchResults searchResults={searchResults} />
        </Container>
    );
}

function SearchResults(props: { searchResults: {
    username: string,
    avatar_url: string,
    id: string
}[] }) {
    return (
        <div>            
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Profile</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Live Session</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {props.searchResults.map(result => {
                        return (
                            <TableRow key={result.id}>
                                <TableCell>
                                    <Typography>{result.username}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Button component={Link} to={"/user/" + result.id} variant="contained">View Profile</Button>
                                </TableCell>
                                <TableCell>
                                    <Button component={Link} to={"/user/" + result.id + "/live" } variant="contained">View Live Session</Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {props.searchResults.length === 0 && <TableRow>
                        <TableCell colSpan={3}>
                            <Typography>No results found</Typography>
                        </TableCell>
                    </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}