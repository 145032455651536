import { Component } from "react";
import { Box, Button, Container, Link, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import livePreview from '../images/live_preview.png';
import sessionResultPreview from '../images/preview_session_result.png';
import f123Logo from '../images/f123-logo-horizontal-light.png';
import f124 from '../images/f1_24_logo.png';
// import f122Logo from '../images/f122-countdown-logo.svg';

export class HomeComponent extends Component {


    componentDidMount(): void {
        document.title = "Pitwall Companion - Home";
    }


    render() {
        return (<div>
            {/* <Box sx={{ width: "100vw", background: "linear-gradient(90deg, rgba(40,44,52,1) 45%, rgba(229,57,53,1) 90%)" }}> */}
            <Box sx={{ width: "100vw" }}>
                <Container maxWidth="xl" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "30px 10px" }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", minHeight: "30vh", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        <Typography variant="h2">Welcome to </Typography>
                        <Typography variant="h1" sx={{ background: "linear-gradient(90deg, rgb(0 85 255) 0%, rgba(229, 57, 53, 1) 30%, rgba(229, 57, 53, 1) 70%, rgb(42 255 198) 100%)", backgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight:"bold" }}>Pitwall Companion</Typography>
                        <Typography variant="h4">Your virtual race engineer for the official Formula 1 games from Codemasters, giving you more information about your race.</Typography>
                        <Typography variant="h6">Download the app and start using it today.</Typography>
                    </Box>
                    <Button variant="contained" color="secondary" size="large">
                        <Link to="/signup" variant="body2" color="inherit" component={RouterLink} sx={{ textDecoration: "none" }}>
                            {"Register now"}
                        </Link>
                    </Button>

                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                        <Typography variant="h6">Supports the official Formula 1 games from Codemasters:</Typography>
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            {/* <img src={f122Logo} alt="F1 2021" style={{ width: "auto", height: "50px", marginRight: "10px", filter: "invert(1)" }} /> */}
                            <img  src={f123Logo} alt="F1 2021" style={{ width: "auto", height: "50px", marginLeft: "10px" }} />
                            <img src={f124} alt="F1 2021" style={{ width: "auto", height: "50px", marginLeft: "10px" }} />

                        </Box>
                    </Box>
                </Container>
            </Box>


            <Paper elevation={7} sx={{ padding: "25px" }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="h4">Live Telemetry</Typography>
                        <Typography variant="h6">See the current session's telemetry data in real-time.</Typography>
                        <ul>
                            <li>Position</li>
                            <li>Lap Times</li>
                            <li>Tyre Info</li>
                            <li>Weather</li>
                            <li>and more</li>
                        </ul>
                    </Box>

                    <img src={livePreview} alt="Live Preview" style={{ width: "auto", borderRadius: "25px", height: "100%", maxHeight: "300px", maxWidth: "40%", transform: "perspective(1500px) rotateY(-15deg)",
                        border: "1rem",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
                        transition: "transform 1s ease 0s", margin: "0 0 0 25px" }} />

                </Box>

            </Paper>


            <Paper elevation={2} sx={{ padding: "25px" }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>

                    <img src={sessionResultPreview} alt="Live Preview" style={{
                        width: "auto", borderRadius: "25px", height: "100%", maxHeight: "400px", maxWidth: "40%", transform: "perspective(1500px) rotateY(15deg)",
                        border: "1rem",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
                        transition: "transform 1s ease 0s", margin: "0 25px 0 0"
                    }} />
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="h4">Session History</Typography>
                        <Typography variant="h6">See the telemetry data of your previous sessions.</Typography>
                        <ul>
                            <li>Finishing positions</li>
                            <li>Lap times</li>
                            <li>Penalties</li>
                            <li>Warnings</li>
                            <li>and more</li>
                        </ul>
                    </Box>

                </Box>
            </Paper>



            {/* <Container maxWidth="xl" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "50px" }}>
                <Box sx={{ marginTop: "100px", display: 'flex', flexDirection: "column" }}>
                    <Typography variant="h3" mb={"10px"}>FAQ</Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            How do I use this app?
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                You have to register and download the app. For the app to work you have to enable the "UDP-Telemetry" in the settings of the F1 game under the "Telemetry Settings" option in the settings menu.
                                To see your username you also have to enable "Show player name" in the same menu.
                                After that, you can start racing and view the live timing by clicking on the button in the app or by going to the "Live" tab and search for your session.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            What data is being collected?
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We are using the game's telemetry data that is transmitted by UDP. We don't collect any information about the car setups and the car telemetry, like throttle or brake.
                                And currently only collect data about session information like its type, the track, the weather, the session time and driver information like the driver's name, the car number, the team, lap times, tyre information, penalties, warnings, and more.
                                A future update will bring support for car telemetry data like, throttle, brake, steering, etc. however, this will be optional.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>

            </Container> */}
            
            </div>);

    }

}