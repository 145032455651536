import { Link, Container } from "@mui/material"
import ReactMarkdown from "react-markdown";

export default function PrivacyComponent() {

   const privacyMarkdown = `
### Privacy Policy

**Effective Date:** 03.01.2024

At PitwallCompanion, your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your data in compliance with the GDPR.

---

#### 1. Data Collection

We collect:

- **Personal Data:** Email address, and login credentials.
- **Uploaded Data:** Files or information you upload to the Web App.
- **Usage Data:** Analytics from tools like Google Analytics.

---

#### 2. Purpose of Data Use

We use your data to:

- Provide and improve our services.
- Monitor and analyze usage patterns.
- Communicate updates or service information.

---

#### 3. Legal Basis for Processing

We process your data based on:

- Your consent.
- Our legitimate interest to improve the Web App.
- Compliance with legal obligations.

---

#### 4. Third-Party Services

We use:

- **Supabase:** For database and authentication.
- **Google Analytics:** For usage tracking.

These services may collect and process data under their own privacy policies. Please review:

- Supabase Privacy Policy: [Supabase Privacy](https://supabase.com/privacy)
- Google Analytics Privacy Policy: [Google Privacy](https://policies.google.com/privacy)

---

#### 5. Data Retention

We retain your data only as long as necessary for service delivery or legal requirements. You may request data deletion by contacting us at contact@pitwallcompanion.com.

---

#### 6. User Rights

Under GDPR, you have the right to:

- Access your data.
- Correct inaccurate information.
- Request data deletion.
- Object to data processing.
- Request data portability.

To exercise these rights, contact us at contact@pitwallcompanion.com.

---

#### 7. Data Security

We implement industry-standard measures to protect your data from unauthorized access, alteration, or destruction.

---

#### 8. Cookies and Tracking

We use cookies and similar technologies to:

- Provide core functionalities.
- Track usage through Google Analytics.

You can manage cookie preferences via your browser settings.

---

#### 9. International Transfers

If your data is processed outside the EU, we ensure it is safeguarded through measures such as standard contractual clauses.

---

#### 10. Policy Updates

We may update this Privacy Policy. Continued use of the Web App constitutes acceptance of changes. Significant updates will be communicated to you directly.

---

#### 11. Contact Information

For privacy inquiries, please contact us at contact@pitwallcompanion.com.

   `;

   return (
      <Container>
         <ReactMarkdown
            components={{
               a: ({ node, ...props }) => {
                  return <Link color="#fff" component="a" href={props.href}> {props.children}</Link>;
               },
            }}
         >{privacyMarkdown}</ReactMarkdown>
      </Container>
   );
}