import { Avatar, Box, Button, Container, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { supabaseClient } from "../../shared/supabase-client";
import { useAuth } from "../../shared/AuthContext";
import { useNavigate } from "react-router-dom";
import driver from "../../images/driver.svg";

  //array of default profile pictures, consiting of the 10 formula 1 teams logo colors
  const defaultColors = [
    "#1E41FF",
    "#00D2BE",
    "#2B4562",
    "#006F62",
    "#0090FF",
    "#DC0000",
    "#005AFF",
    "#FF8700",
    "#960000",
    "#FFFFFF"
  ]

  async function getDriverImage(idx: number): Promise<string> {
    const color = defaultColors[idx];

    //create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = 96;
    canvas.height = 96;
    const ctx = canvas.getContext("2d");

    if (ctx === null) {
      return "";
    }
    //draw a circle
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(48, 48, 48, 0, 2 * Math.PI);
    ctx.fill();

    //draw the driver image
    const img = new Image();
    img.src = driver;

    var promise = new Promise<void>((resolve, reject) => {
      img.onload = () => {
        
        ctx.drawImage(img, 24, 18, 48, 60);
        resolve();
      }
    });

    await promise;

    return canvas.toDataURL();

  }

export default function RegisterInit() {

    const {session} = useAuth();

    const [username, setUsername] = useState<string>("");

    const [driverImages, setDriverImages] = useState<string[]>([]);

    const [selectedImage, setSelectedImage] = useState<number>(-1);

    useEffect(() => {
      const fetchDriverImages = async () => {
        const images = await Promise.all(Array.from({ length: 10 }, (_, i) => getDriverImage(i)));
        setDriverImages(images);
      }

      fetchDriverImages();
    }, []);

    
    const steps = {
      "Choose your username": { component: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Choose your username:</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"center" }}>

                    <TextField placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}  sx={{margin:5}}/>
                </Box>
          </Box>
      ), optional: false,
      canGoNext: () => {
        return username !== "";
      }
    },
      "Choose a profile picture": { component: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Choose a profile picture:</Typography>
                <Box sx={{ display: "grid", flexDirection: "row", alignItems: "center", gridTemplateColumns: "repeat(5, 1fr)" }}>
                    {Array.from({ length: 10 }, (_, i) => 
                    {
                      const driverImg = driverImages[i];
                        return (
                        <Box key={i} sx={{ position: 'relative' }}>
                          <Avatar 
                          onClick={() => setSelectedImage(i)} 
                          sx={{ 
                            width: 96, 
                            height: 96, 
                            margin: 2, 
                            '&:hover': {
                            cursor: "pointer",
                            opacity: 0.8
                            },
                            border: selectedImage === i ? '2px solid green' : 'none'
                          }} 
                          src={driverImg}
                          />
                          {selectedImage === i && (
                          <Box 
                            sx={{ 
                            position: 'absolute', 
                            top: 0, 
                            right: 0, 
                            bgcolor: 'green', 
                            borderRadius: '50%', 
                            width: 24, 
                            height: 24, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center' 
                            }}
                          >
                            ✓
                          </Box>
                          )}
                        </Box>
                        )})}
                </Box>
          </Box>
      ),
      optional: true,
      canGoNext: () => {
        return selectedImage !== -1;
      }
    },
      "Your profile is ready!": { component: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Your profile is ready!</Typography>
          </Box>
      ),
      optional: false
    }
    }

    const navigate = useNavigate();
    const handleFinish = () => {

      if(selectedImage === -1) {
        return;
      }

      const selectedDriverImage = driverImages[selectedImage];

      //upload the profile picture to the database
      supabaseClient.storage.from("avatars").upload(`avatar_${session?.user.id}`, selectedDriverImage).then((data) => {
        console.log("Image uploaded successfully");

        //get the url of the uploaded image
        const url = data.data?.path;

        supabaseClient.from('profiles').upsert({
          username: username,
          id: session?.user.id,
          updated_at: new Date().toISOString(),
          avatar_url: url
        }).then(() => {
          console.log("Profile created successfully");
          navigate("/profile");
        });

      });
    }
    //return input field for the username and a profile picture upload field with 10 default profile pictures to choose from
    return (
        <Container maxWidth="sm">
            <Typography variant="h4" sx={{margin: 2, marginTop: 5}}>
              Create your profile</Typography>
            <HorizontalLinearStepper steps={steps} handleFinish={handleFinish}/>
        </Container>
    );
}


interface IStepperProps {
  steps: Record<string, {
    component: JSX.Element;
    optional?: boolean;
    canGoNext?: () => boolean;
  }>;
  handleFinish: () => void;
}

function HorizontalLinearStepper({steps, handleFinish}: IStepperProps, ) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return steps[Object.keys(steps)[step]].optional;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if(activeStep === Object.keys(steps).length - 1) {
      handleFinish();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const length = Object.keys(steps).length;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {Object.keys(steps).map((label, index) => {
        // {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {steps[Object.keys(steps)[activeStep]].component}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext} disabled={steps[Object.keys(steps)[activeStep]].canGoNext ? !steps[Object.keys(steps)[activeStep]].canGoNext!() : false}>
              {activeStep === length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}