import React, { Component, useEffect, useState } from "react";
import "./sessions.scss";
import { Link } from "react-router-dom";
import { Card, CardContent, Container, Typography } from "@mui/material";
import { LiveSessionClient, SessionHeader } from "../ApiClient";
import { getSessionNameFromType } from "../shared/utils";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import { getImageForTrackId } from "../components/TrackMapComponent";

type State = {
    sessions: SessionHeader[]
}

export class LiveSessionsComponent extends Component<{}, State> {

    private client: LiveSessionClient;
    private interval: NodeJS.Timeout | null = null;
    constructor(props: {}) {

        super(props);
        this.state = { sessions: [] };
        this.client = new LiveSessionClient();
    }

    componentDidMount() {

        document.title = "Pitwall Companion - Live Sessions";
        //get session data from /api/sessions 
        //and display it in a table
        this.client.getAllSessions().then(sessions => {
            this.setState({ sessions: sessions });
        }).catch((error) => {
            //TODO: handle error
        });

        //refresh every 5 seconds
        this.interval = setInterval(() => {
            this.client.getAllSessions().then(sessions => {
                this.setState({ sessions: sessions });
            }).catch((error) => {
                //TODO: handle error
            });
        }, 5000);

    }

    componentWillUnmount(): void {
        //cleanup
        if (this.interval) {
            clearInterval(this.interval);
        }
    }


    render() {

        //if there are no sessions, display a message
        if (this.state.sessions.length === 0) {
            return (
                <Container maxWidth="md" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
                    <div>
                        <Typography variant="h2">No live sessions</Typography>
                        <Typography variant="h4">Start racing with the app open to see your race session here.</Typography>
                    </div>
                </Container>
            );
        }

        return (
        <div>
            <Typography variant="h1" style={{textAlign: "center"}}>Live Sessions</Typography>
        <Container maxWidth="lg" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            {this.state.sessions.map((session,idx) => {
                return (
                        <LiveSessionCard session={session} key={idx}></LiveSessionCard>
                );
            })}
        </Container>
        </div>
    );
    }
}



function LiveSessionCard({session}: {session: SessionHeader}) {

    let startDate = new Date(session.sessionStartTime!);
    let now = new Date();
    let duration = now.getTime() - startDate.getTime();

    let [sessionTime, setSessionTime] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setSessionTime(sessionTime + 1000);
        }, 1000);
        return () => clearInterval(interval);
    }, [sessionTime]);

    const trackImage = getImageForTrackId(session.trackId!, session.gameVersion!);

    return (
        <Card className="session" sx={{margin: "10px", flex: "1 0 30%", maxWidth: "50%"}}>
        <CardContent>
            <Link to={"/live/"+session.id}>
                {/* <CardMedia
                    // image={getFlagIcon(session.trackId!)}
                    // src={
                    //     <img src={getImageForTrackId(session.trackId!, session.gameVersion!)} alt={Track[session.trackId!]} style={{width: "100%", height: "auto"}}/>
                    // }
                    title={Track[session.trackId!]}
                    sx={{height: "200px", width: "80%"}}
                /> */}
                { trackImage &&
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="100%" height="100%">
                    <image href={trackImage} height="100%" width="100%"/>
                </svg>
                }
                {/* <img src={getImageForTrackId(session.trackId!, session.gameVersion!)} alt={Track[session.trackId!]} style={{width: "100%", height: "200px"}}/> */}
                <Typography variant="h5">
                    <TrackInfoComponent track={session.trackId!} format="small"></TrackInfoComponent>
                </Typography>
                <Typography variant="h5">
                    User: {session.userName}
                </Typography> 
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {getSessionNameFromType(session.sessionType!)}
                </Typography>
                <Typography variant="body2">
                    {/*Start: {startDate.toLocaleString()}<br/> */}
                    {/* End: {endDate.toLocaleString()}<br/> */}
                    Laps: {session.numberOfLaps}<br/>
                    Session Time: {formatDuration(sessionTime)}
                </Typography>
            </Link>
        </CardContent>
    </Card>
    );
}



const formatDuration = (duration: number) => {
    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return hours + ":" + minutes + ":" + seconds;
}