import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Session } from "@supabase/supabase-js";
import { supabaseClient } from "../../shared/supabase-client";

export type UserLoginInfo = {
    email: string,
    password: string,
}

export const logoutUser = createAsyncThunk('user/logoutUser', () => {
    // AuthService.logout();
    return;
});

export const updateSession = createAction<Session>('user/updateSession');


export const updateProfile = createAsyncThunk('user/updateProfile', async () => {

    const user = await supabaseClient.auth.getUser();
    if (!user || user.data.user === null) {
        throw new Error("User not found");
    }

    const { data, error } = await supabaseClient.from('profiles').select("username,avatar_url").eq('id', user.data.user?.id).single();

    if (error) {
        console.error(error);
        // throw error;
        return { username: "", avatar: "" };
    }
    return { username: data?.username, avatar: data?.avatar_url };
});
        