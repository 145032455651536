import { Container, Box, Typography, Button, TextField } from "@mui/material";
import React from "react";


export default function ResendConfirmationComponent() {    
    const [errors, setErrors] = React.useState([] as string[]);
    const [success, ] = React.useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let errs = [] as string[];
        let isValid = true;

        if (!data.get('email')?.toString().match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            errs.push("Invalid email");
            isValid = false;
        }
        setErrors(errs);
        if (!isValid) {
            return;
        }
    };

    return (
        <Container>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "60vh", flexDirection: "column" }}>
                <Typography variant="h1">Resend Confirmation Email</Typography>
                <Typography variant="body1">Please enter your email address below. We will send you an email with a link to confirm your email if an account exists with that email.</Typography>
                <Box component={"form"} onSubmit={handleSubmit}
                sx={{ display: "block", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "25px" }}>
                    <TextField type="email" 
                        // placeholder="Email Address" 
                        required
                        fullWidth
                        name="email"
                        label="Email Address"
                        autoComplete="email"
                        id="email"
                        style={{ padding: "10px", margin: "10px" }} />
                    <Button style={{ padding: "10px", margin: "10px" }} 
                        variant="contained"
                        fullWidth
                        type="submit">Resend Confirmation</Button>

                    <Typography variant="body1" style={{ display: success ? "block" : "none" }} color="success">An email has been sent to your email address with a link to confirm it.</Typography>
                    <Typography variant="body1" style={{ display: errors.length > 0 ? "block" : "none" }} color="error">{errors.map((err) => <div>{err}</div>)}</Typography>
                </Box>
            </Box>
        </Container>
    )
}