import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { SessionDriver } from "../ApiClient";
import { abudhabi, abudhabi23, australi, australiaNew, austria, azerbaijan, bahrain, belgium, brazil, canada, china, france, greatbritain, hungary, imola, italy, japan, jeddah, lasvegas, mexico, miami, monaco, netherlands, qatar, russia, singapore, singapore2023, spain, spain2023, usa, vietnam } from "./TrackImages";
import { Paper } from "@mui/material";
import { getTeamColors } from "../shared/f1utils";


interface TrackMapProps {
    trackId: number,
    drivers: SessionDriver[],
    trackLength: number,
    gameYear: number,
}

interface TrackSize {
    xMax: number,
    xMin: number,
    zMax: number,
    zMin: number,
    aspectRatio: number,
}

const trackSizeDict: { [key: number]: TrackSize | {[key:number] : TrackSize}  } = {
    0: { xMax: 745.7313842773438, xMin: -740.1177978515625, zMax: 878.8071899414062, zMin: -881.3482666015625, aspectRatio: 0.8441579274180626 },
    1: { xMax: 1043.5252685546875, xMin: -1080.13037109375, zMax: 575.0910034179688, zMin: -662.5326538085938, aspectRatio: 1.7159139026215913 },
    10: {
        2018: { xMax: 549.5548706054688, xMin: -730.449951171875, zMax: 982.2044677734375, zMin: -1072.6148681640625, aspectRatio: 0.6229281569385995 },
        2024: { xMax: 632.7680053710938, xMin: -635.8297729492188, zMax: 1019.6644287109375, zMin: -1011.1392211914062, aspectRatio: 0.6246777123831327 },
    },
    11: { xMax: 634.6837158203125, xMin: -634.7080078125, zMax: 1093.2679443359375, zMin: -1089.07958984375, aspectRatio: 0.5816634169176718 },
    12: { xMax: 735.80126953125, xMin: -718.8549194335938, zMax: 488.0032958984375, zMin: -438.1236267089844, aspectRatio: 1.5706877248200475 },
    13: { xMax: 999.6611328125, xMin: -1002.9796752929688, zMax: 499.75341796875, zMin: -500.46661376953125, aspectRatio: 2.0022002605017635 },
    14: { xMax: 804.3078002929688, xMin: -726.4786376953125, zMax: 651.3734130859375, zMin: -322.0777282714844, aspectRatio: 1.5725354596160699 },
    15: { xMax: 999.6529541015625, xMin: -836.7886352539062, zMax: 1046.0390625, zMin: -48.43928527832031, aspectRatio: 1.6779149565482572 },
    16: { xMax: 103.4500961303711, xMin: -573.383056640625, zMax: 705.596435546875, zMin: -345.91485595703125, aspectRatio: 0.6436765427435086 },
    17: { xMax: 738.9402465820312, xMin: -540.6351928710938, zMax: 309.74420166015625, zMin: -486.4315185546875, aspectRatio: 1.6071520481783073 },
    19: { xMax: 515.099853515625, xMin: -1025.14892578125, zMax: 73.08612823486328, zMin: -1030.232177734375, aspectRatio: 1.396014886151829 },
    2: { xMax: 618.9215698242188, xMin: -619.4677124023438, zMax: 546.4783935546875, zMin: -542.924560546875, aspectRatio: 1.136759614579777 },
    20: { xMax: 893.2245483398438, xMin: -1190.86083984375, zMax: 598.40966796875, zMin: -896.9955444335938, aspectRatio: 1.393659304447351 },
    26: { xMax: 504.6897888183594, xMin: -473.3670959472656, zMax: 425.84759521484375, zMin: -417.6697998046875, aspectRatio: 1.1594981805241593 },
    27: { xMax: 924.4747924804688, xMin: -926.269287109375, zMax: 494.5096435546875, zMin: -467.1073913574219, aspectRatio: 1.9246165702118614 },
    28: { xMax: 451.30706787109375, xMin: -243.8775634765625, zMax: 580.7913208007812, zMin: -588.0040283203125, aspectRatio: 0.5947873011904252 },
    29: { xMax: 301.7208251953125, xMin: -299.9815368652344, zMax: 1377.14599609375, zMin: -1376.2093505859375, aspectRatio: 0.21853421963356412 },
    3: { xMax: 425.50726318359375, xMin: -412.53631591796875, zMax: 601.5540771484375, zMin: -614.6735229492188, aspectRatio: 0.6890516043496072 },
    30: { xMax: 755.462158203125, xMin: -756.7406005859375, zMax: 294.80029296875, zMin: -305.9635009765625, aspectRatio: 2.5171336455850373 },
    31: { xMax: 591.4234619140625, xMin: -592.5795288085938, zMax: 957.7615966796875, zMin: -1000.4520263671875, aspectRatio: 0.6046342323369251 },
    32: { xMax: 633.9269409179688, xMin: -633.4572143554688, zMax: 741.40771484375, zMin: -740.2841796875, aspectRatio: 0.8553628186475224 },
    4: { xMax: 450.9025573730469, xMin: -531.1428833007812, zMax: 566.749267578125, zMin: -611.5551147460938, aspectRatio: 0.8334395215748348 },
    5: { xMax: 397.4830627441406, xMin: -358.02618408203125, zMax: 510.6690673828125, zMin: -457.3669738769531, aspectRatio: 0.7804557006400099 },
    6: { xMax: 437.5945129394531, xMin: -196.56851196289062, zMax: 1475.7781982421875, zMin: -450.1729431152344, aspectRatio: 0.3292726441935499 },
    7: { xMax: 414.1020202636719, xMin: -618.5298461914062, zMax: 991.3800048828125, zMin: -752.8319091796875, aspectRatio: 0.5920334898125664 },
    9: { xMax: 471.2521057128906, xMin: -612.5094604492188, zMax: 598.3172607421875, zMin: -620.9779052734375, aspectRatio: 0.8888426661311156 },
}


export function getTrackSize(trackId: number, gameYear: number) : TrackSize {

    
    if (trackSizeDict[trackId] instanceof Object && !trackSizeDict[trackId].hasOwnProperty("xMax")) {
        let trackSizes = trackSizeDict[trackId] as {[key:number] : TrackSize};
        //get the track size for the highest year that is lower than the current year
        let years = Object.keys(trackSizeDict[trackId]);
        years = years.filter(year => Number(year) <= gameYear).sort().reverse();
        let year = Number(years[0]);
        return trackSizes[year];

    } else {
        return trackSizeDict[trackId] as TrackSize;
    }
}

export function getImageForTrackId(trackId: number, gameYear: number) {
        switch (trackId) {
            case 0:
                if (gameYear >= 2022) {
                    return australiaNew;
                }
                else {
                    return australi;
                }
            case 1:
                return france;
            case 2:
                return china;
            case 3:
                return bahrain;
            case 4:
                if (gameYear >= 2023) {
                    return spain2023;
                }
                else {
                    return spain;
                }
            case 5:
                return monaco
            case 6:
                return canada;
            case 7:
                return greatbritain;
            // case 8:
            //     return germany;
            case 9:
                return hungary;
            case 10:
                return belgium;
            case 11:
                return italy;
            case 12:
                if (gameYear >= 2023) {
                    return singapore2023;
                }
                else {
                    return singapore;
                }
            case 13:
                return japan;
            case 14:
                if (gameYear >= 2022) {
                    return abudhabi23;
                }
                else {
                    return abudhabi;
                }
            case 15:
                return usa;
            case 16:
                return brazil;
            case 17:
                return austria;
            case 18:
                return russia;
            case 19:
                return mexico;
            case 20:
                return azerbaijan;
            case 25:
                return vietnam;
            case 26:
                return netherlands;
            case 27:
                return imola;
            case 29:
                return jeddah;
            case 30:
                return miami;
            case 31:
                return lasvegas;
            case 32:
                return qatar;
            default:
                return null;
        }
    }

export function TrackMapComponent(props: TrackMapProps) {

    const imageRef = useRef<SVGImageElement>(null);
    const dimensions = useImageCoordinates(imageRef);

    let trackSize = getTrackSize(props.trackId, props.gameYear);

    const isXDominant = trackSize.aspectRatio > 1;

    const width = isXDominant ? dimensions.width! : dimensions.height! * trackSize.aspectRatio;
    const height = isXDominant ? dimensions.width! / trackSize.aspectRatio : dimensions.height!;

    const additionalMarginX = (dimensions.width! - width) / 2;
    const additionalMarginZ = (dimensions.height! - height) / 2;

    const rangeX = useMemo(() =>
        [trackSize.xMin, trackSize.xMax]
        , [trackSize]);
    
    const rangeZ = useMemo(() =>
        [trackSize.zMin, trackSize.zMax]
        , [trackSize]);


    const scaleOffsetZ = useMemo(() => d3.scaleLinear().domain(rangeZ).range([0, height]).clamp(false), [rangeZ, height]);
    const scaleOffsetX = useMemo(() => d3.scaleLinear().domain(rangeX).range([0, width]).clamp(false), [rangeX, width]);

    const marginX = dimensions.x!+additionalMarginX;
    const marginZ = dimensions.y!+additionalMarginZ;

    const color = (x:number) => getTeamColors(props.gameYear)[x];

    const trackImage = getImageForTrackId(props.trackId, props.gameYear);

    return (
        <Paper style={{flex: "1 1 auto", display:"flex", flexDirection:"column", minHeight: "300px", marginTop: "10px", maxHeight:"70vh"}}>
            {/* <div> xMax: {this.state.xMax}  xMin: {this.state.xMin} zMax: {this.state.zMax} zMin: {this.state.zMin}</div> */}
            <div style={{flex:"2 1 auto", width:"100%"}} >
    
    { trackImage &&
    <svg width={"100%"} height={"100%"} viewBox="0 0 1000 1000">
        <g>
            <image xlinkHref={trackImage} ref={imageRef} x={"5%"} y={"5%"} width={"90%"} height={"90%"}/>
        </g>
        <g
            transform={`translate(${marginX}, ${marginZ})`}
        >
            {props.drivers.map((d, idx) => {
                return <g transform={`translate(${scaleOffsetX(d.livePosition?.x!)}, ${scaleOffsetZ(d.livePosition?.z!)})`} key={idx}>
                    <circle r={25} fill={color(d.team!)} />
                    <text dx={d.position! < 10 ? -10 : -20} dy={0} fill="white" alignmentBaseline="middle" stroke="black" fontSize={"35px"} fontWeight="900" style={{strokeWidth: "2px"}}>{d.position}</text>
                </g>
            })
            }
        </g>
    </svg> }
    { !trackImage && <div>Track image not found</div>}
    </div>
        
        </Paper>)

}

function useImageCoordinates(ref: React.RefObject<SVGImageElement>) {
    
    const getDimensions = useCallback(() => {
        return {
            width: ref.current?.getBBox().width,
            height: ref.current?.getBBox().height,
            x: ref.current?.getBBox().x,
            y: ref.current?.getBBox().y,
        }
    }, [ref]);

    const [dimensions, setDimensions] = useState(getDimensions);

    const handleResize = useCallback(() => {
        setDimensions(getDimensions());
    }, [getDimensions]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    useLayoutEffect(() => {
        handleResize();
    }, [handleResize]);

    return dimensions;
}

