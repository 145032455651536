const colors2023 = ["#00a19c", "#a6051a", "#121f45", "#041E42", "#00352f", "#005ba9", "#00293f", "#f9f2f2", "#ff8000", "#981E32"];

const textColors2023 = ["#ffffff", "#000000", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#000000", "#ffffff", "#000000"];

const colors2024 = ["#27F4D2", "#E8002D", "#3671C6", "#64C4FF", "#229971", "#0092cc", "#6692FF", "#B6BABD", "#FF8000", "#52E252"];


export function getTeamColor(team: number, year:number) : string{

    if(team < 0 || team > 9){
        return "grey";
    }
    if(year === 2023 || year === 2022){
        return colors2023[team];
    }
    if(year === 2024){
        return colors2024[team];
    }

    return colors2023[team];

}

export function getTeamTextColor(team: number, year:number) : string{

    if(team < 0 || team > 9){
        return "black";
    }
    if(year === 2023 || year === 2022){
        return textColors2023[team];
    }

    return textColors2023[team];
}

export function getTeamColors(year:number){
    if(year === 2023 || year === 2022){
        return colors2023;
    }
    if(year === 2024){
        return colors2024;
    }
    return colors2023;
}