import React from "react";
import { QualifyingLeaderboardComponent } from "../components/QualifyingLeaderboardComponent";
import { RaceLeaderboardComponent } from "../components/RaceLeaderboardComponent";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import { WeatherListComponent } from "../components/WeatherListComponent";
import { RoutedProps } from "../App";
import { LiveSessionClient, Session, SessionType } from "../ApiClient";
import { Box, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TrackMapComponent } from "../components/TrackMapComponent";

type LiveSessionState = {
    session: Session,
    sessionId: string
}

export class LiveSessionComponent extends React.Component<RoutedProps, LiveSessionState> {
    updateInterval!: NodeJS.Timer;
    socket?: WebSocket;

    constructor(props: RoutedProps) {
        super(props);
        this.state = {
            session: new Session(),
            sessionId: ""
        };
    }


    componentDidMount() {

        document.title = "Pitwall Companion - Live Session";

        const client = new LiveSessionClient();

        client.getSession(this.props.location.pathname.split("/")[2]).then(session => {
            this.setState({ session: session });
        }).catch((error) => {});
        this.setState({ sessionId: this.props.location.pathname.split("/")[2] });
        const id = this.props.location.pathname.split("/")[2];
        const scheme = document.location.protocol === "https:" ? "wss" : "ws";
        const port = document.location.port ? (":" + document.location.port) : "";
        // console.log("Connecting to " + scheme + "://" + document.location.hostname + port + "/api/livesession/ws/"+ this.state.sessionId);
        const connectionUrl = scheme + "://" + document.location.hostname + port + "/api/livesession/ws/" + id;
        this.socket = new WebSocket(connectionUrl);


        this.socket.onopen = () => {
            console.log('WebSocket connection established');

            // setup heartbeat
            const sendingInterval = setInterval(() => {
                try {
                    this.socket?.send(JSON.stringify({
                        type: 'heartbeat'
                    }));
                } catch (e: any) {
                    console.error('Error sending heartbeat:', e);
                    if (e instanceof DOMException && e.name === 'InvalidStateError') {
                        clearInterval(sendingInterval);
                    }
                }
            }, 10000);
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === 'session') {
                this.setState({
                    session: data.data
                });
                // if data is null, close the connection
                if (data.data === null) {
                    this.socket?.close(4154, "Session ended");
                }
            }
        };



        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    componentWillUnmount() {
        // clearInterval(this.updateInterval);
        this.socket?.close(4154, "User closed the page");
    }



    render() {
        if (!this.state.session || !this.state.session.drivers) {
            return (<Container maxWidth="md" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
                <Box>
                    <Typography variant="h3">The sessions seems to have ended.</Typography>
                    <Typography variant="h3">Go to the <Link to={"/sessions/" + this.state.sessionId}>Session Summary</Link></Typography>
                </Box>
            </Container>)
        }
        return <LiveSessionInternalComponent session={this.state.session}></LiveSessionInternalComponent>;
    }
}

export class LiveSessionInternalComponent extends React.Component<{ session: Session }, {}> {
    formatTime(timeInSeconds: number | undefined) {
        if (timeInSeconds === undefined) {
            return "0:00";
        }
        let hours = Math.floor(timeInSeconds / 3600);
        let minutes = Math.floor((timeInSeconds % 3600) / 60);
        let seconds = Math.floor(timeInSeconds % 60);
        //don't display hours if it's 0
        if (hours === 0) {
            return `${minutes}:${seconds.toString().padStart(2, "0")}`;
        }
        return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    isRace(sessionType: SessionType) {
        switch (sessionType) {
            case SessionType.Race:
            case SessionType.Race2:
                return true;
            default:
                return false;
        }
    }

    render() {
        let currentLapNum = this.props.session?.drivers?.map(driver => driver.currentLapNumber!).reduce((a, b) => Math.max(a, b), 0);
        let isSafetyCar = this.props.session.safetyCarStatus === 1 || this.props.session.safetyCarStatus === 2;
        return (
            <div>
                <Box sx={{display: "flex", justifyContent: "space-around", alignItems:"center",  backgroundColor: (isSafetyCar ? "yellow" : ""), color: (isSafetyCar? "black" : null)}} >
                    <Typography variant="h3">{SessionType[this.props.session.sessionType!]}</Typography>
                    <TrackInfoComponent track={this.props.session.trackId!}></TrackInfoComponent>
                    <Typography variant="h3">
                    {this.props.session.safetyCarStatus === 1 ? "Safety Car" : this.props.session.safetyCarStatus === 2 ? "Virtual Safety Car" : null}</Typography>
                    <Typography variant="h3">Lap {currentLapNum}/{this.props.session.numberOfLaps}</Typography>
                    <Typography variant="h3">Time: {this.formatTime(this.props.session.sessionTimeLeft)} left</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column"} , maxWidth:"100vw", maxHeight:"100vh"}}>
                    <Paper sx={{margin:"5px"}}>
                    {(this.isRace(this.props.session.sessionType!) || true) ? <RaceLeaderboardComponent drivers={this.props.session.drivers!}></RaceLeaderboardComponent> : <QualifyingLeaderboardComponent drivers={this.props.session.drivers!}></QualifyingLeaderboardComponent>}
                    </Paper>
                    <Box sx={{display: "flex", flexDirection:"column", flex:"1 1 30%", margin:"5px"}}>
                        <WeatherListComponent weatherSamples={this.props.session.weatherForecastSamples!} currentSession={this.props.session.sessionType!}></WeatherListComponent>

                        <TrackMapComponent trackId={this.props.session.trackId!} drivers={this.props.session.drivers!} trackLength={this.props.session.trackLength || 0} gameYear={this.props.session.gameVersion!}></TrackMapComponent>
                    </Box>
                </Box>
            </div>
        );
    }
}