import React from "react";
import { SessionType, Weather } from "../TelemetryInterfaces";
import { WeatherImageComponent } from "./WeatherImageComponent";
import "./weatherList.scss";
import { WeatherForecastSample } from "../ApiClient";
import { Box, Card, Paper } from "@mui/material";




export class WeatherListComponent extends React.Component<{ weatherSamples: Array<WeatherForecastSample>| undefined, currentSession: number }, { selectedSession: number }>{


    constructor(props: any) {
        super(props);
        this.state = { selectedSession: props.currentSession };
    }

    groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
        arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);


    render() {

        if (this.props.weatherSamples === undefined) return (<div> No weather data available</div>);

        let weatherGrouped = this.groupBy(this.props.weatherSamples, w => w.sessionTypeMode!);

        // console.log(weatherGrouped);
        //@ts-ignore
        let selectedWeather: Weather[] = weatherGrouped[this.props.currentSession];
        selectedWeather = selectedWeather ? selectedWeather : []

        return (
        <Paper sx={{width: "fit-content",  maxHeight:"50%", overflow:"auto"}} >
            <Box sx={{display: "flex", padding: "5px",  height:"100%"}}>
            <Box sx={{display: "flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"center", justifyItems:"flex-end", marginLeft:"5px"}}>
                <div></div>
                <div>Air Temp</div>
                <div>Track Temp</div>
                <div>Rain</div>
                <div>Time</div>
            </Box>

            {selectedWeather.length === 0 ? <div>No weather data</div> :
                <div className="sessionWeatherList">

                    {Object.keys(weatherGrouped).map((key: string) => {
                        return (
                            <Card sx={{ margin:"5px", padding:"5px", borderRadius:"5px"}} elevation={2}>
                            <Box sx={{ textAlign:"center"}}>
                                {SessionType[parseInt(key)]}
                                <div className="weatherBlockContainer">
                                    {weatherGrouped[parseInt(key) as SessionType].map((weather: WeatherForecastSample) => {
                                        return (
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                <WeatherImageComponent weather={weather.forecastedWeatherCondition!} />
                                                <div>{weather.airTemperatureCelsius} °C</div>
                                                <div>{weather.trackTemperatureCelsius} °C</div>
                                                <div>{weather.rainPercentage}%</div>
                                                <div>{weather.timeOffSet} min </div>
                                            </Box>
                                        );
                                    })}
                                </div>
                            </Box>
                            </Card>
                        );
                    })}
                </div>
            }

        </Box>
        </Paper>)
    }
}