import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";



export default function FAQComponent() {
    return (
        <Container maxWidth="xl" style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: "50px" }}>
            <Box sx={{ marginTop: "100px", display: 'flex', flexDirection: "column" }}>
                <Typography variant="h3" mb={"10px"}>FAQ</Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        What is Pitwall Companion?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Pitwall Companion is a telemetry app for the F1 games by Codemasters. It provides live telemetry data of the current session, like the position, lap times, tyre information, weather, and more.
                            Furthermore it provides a session history with detailed information about your previous sessions.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        How do I use this app?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            You have to register and download the app, and log in with your account.
                            For a detailed guide on how to set up the app, please visit check <Link to="https://docs.google.com/document/d/1_fhl4Naun-jvez1PuRLyvLlWk0ZonHEz01WOelk7pFo/edit?usp=sharing">here</Link>.

                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        What data is being collected?
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We are using the game's telemetry data that is transmitted by UDP. We don't collect any information about the car setups and the car telemetry, like throttle or brake.
                            And currently only collect data about session information like its type, the track, the weather, the session time and driver information like the driver's name, the car number, the team, lap times, tyre information, penalties, warnings, and more.
                            A future update will bring support for car telemetry data like, throttle, brake, steering, etc. however, this will be optional.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Container>
    );
}