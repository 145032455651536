import { Container, Box, Typography, Button, TextField } from "@mui/material";
import React from "react";
import { supabaseClient } from "../shared/supabase-client";

export default function ResetPasswordConfirmComponent() {
    const [errors, setErrors] = React.useState([] as string[]);
    const [success, setSuccess] = React.useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSuccess(false);
        const data = new FormData(event.currentTarget);

        supabaseClient.auth.updateUser({password: data.get('newPassword') as string}).then((response) => {
            if (response.error) {
                setErrors([response.error.message]);
                return;
            }
            setErrors([]);
            setSuccess(true);
        });
    };

    return (
        <Container>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "60vh", flexDirection: "column" }}>
                <Typography variant="h1">Reset Password</Typography>
                <Box component={"form"} onSubmit={handleSubmit}
                    sx={{ display: "block", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "25px" }}>
                    <Typography variant="body1">Please enter your new password below.</Typography>
                    <TextField type="password" 
                        required
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        autoComplete="newPassword"
                        id="newPassword"
                        style={{ padding: "10px", margin: "10px" }} />
                    
                    <Button style={{ padding: "10px", margin: "10px" }} 
                        variant="contained"
                        fullWidth
                        type="submit">Save New Password</Button>

                    <Typography variant="body1" style={{ display: success ? "block" : "none" }} color="success">Your password has been reset, go and login with your new password.</Typography>
                    <Typography variant="body1" style={{ display: errors.length > 0 ? "block" : "none" }} color="error">{errors.map((err) => <div>{err}</div>)}</Typography>
                </Box>
            </Box>
        </Container>
    )
}