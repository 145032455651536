import { createContext, useContext, useEffect, useState } from "react";
import { supabaseClient } from "./supabase-client";
import { Session } from "@supabase/supabase-js";
import axios, { AxiosInstance } from 'axios';
import { updateProfile } from "../features/user/userActions";
import { useAppDispatch } from "../hooks";



const AuthContext = createContext<{session: Session | null | undefined, signOut: () => void, axiosInstance: AxiosInstance| undefined, loading: boolean}>({ session: undefined, signOut: () => {}, axiosInstance: undefined, loading:false });

const axiosInstance = axios.create();

export const AuthProvider = ({children}: {children: React.ReactNode}) => {

    const [session, setSession] = useState<Session| null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if(axiosInstance) {
            axiosInstance.interceptors?.request.use(async (config) => {
                if (session) {
                    config.headers.Authorization = `Bearer ${session.access_token}`;
                }
                return config;
            });
        }
    }, [session]);

    useEffect(() => {

        const setData = async () => {
            const {data: {session}} = await supabaseClient.auth.getSession();
            setSession(session);
            setLoading(false);
        };
        
        const { data: subscription } = supabaseClient.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setLoading(false);
            dispatch(updateProfile());
        });

        setData();

        const refreshInterval = setInterval(() => {
            supabaseClient.auth.refreshSession().then(({data, error}) => {
                if (data) {
                    setSession(data.session);
                }
            });
        }, 1000 * 60 * 30);

        return () => {
            subscription?.subscription.unsubscribe();
            clearInterval(refreshInterval);
        }
    }, [dispatch]);





    const value = {
        session,
        signOut: async () => {
            await supabaseClient.auth.signOut();
        },
        axiosInstance,
        loading
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};