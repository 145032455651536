import { createSlice } from '@reduxjs/toolkit'
import { logoutUser, updateProfile, updateSession } from './userActions';
import { push } from "redux-first-history";
import { Session } from '@supabase/supabase-js';


const initialState : {
    loading: boolean,
    success: boolean,
    error: any,
    isLoggedIn: boolean,
    userProfile: {
        username: string,
        avatar: string,
    }
    session: Session | null
}= {
    loading: false,
    success: false,
    error: null,
    isLoggedIn: false,
    session: null,
    userProfile: {
        username: "",
        avatar: ""
    }
}

export const loggedInSlice = createSlice({
    name: 'loggedIn',
    initialState: initialState,
    reducers: {
        login: (state) => {
        // state.value = true;
        },
        logout: (state) => {
            state.success = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            state.loading = false;
            state.success = false;
            state.isLoggedIn = false;
        });
        builder.addCase(updateSession, (state, action) => {
            state.session = action.payload;
        });
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.userProfile = action.payload;
        });
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.error = action.error;
        });
    }
});

export const userInfoMiddleware = (store: any) => (next: any) => (action: any) => {
    if (action.type === updateProfile.fulfilled.type && action.payload.username === "") {
        store.dispatch(push("/registerInit"));
    }
    next(action);
}

// Action creators are generated for each case reducer function
export const { login, logout } = loggedInSlice.actions

export default loggedInSlice.reducer