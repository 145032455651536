import { useParams } from "react-router-dom";
import { Session, SessionsClient} from "../ApiClient";
import { useEffect, useState } from "react";
import { SessionRow } from "./SessionsComponent";
import { Avatar, Box, Button, CircularProgress, Container, List, Pagination, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { supabaseClient } from "../shared/supabase-client";

interface SupaProfileProps {
    id: string
    username: string
    avatar_url: string
}

export function ProfileComponent() {
    const [user, setUser] = useState<SupaProfileProps | undefined>(undefined);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [avatar, setAvatar] = useState<string | undefined>(undefined);

    const { userId } = useParams();

    useEffect(() => {
        document.title = "Pitwall Companion - Profile";

        // Get the profile of the user
        supabaseClient.from("profiles").select("id, username, avatar_url").eq("id", userId).then(({ data, error }) => {
            if (error || data?.length === 0) {
                // If the user does not exist, redirect to the 404 page
                window.location.href = "/404";
            }
            setUser(data![0]);
        });

        supabaseClient.storage.from("avatars").download("avatar_" + userId).then(({ data, error }) => {
            if (error) {
                console.error(error);
            } else {
                if (data) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setAvatar(reader.result as string);
                    };
                    reader.readAsText(data);
                }
            }
        });
    }, [userId]);

    useEffect(() => {
        const sessionsClient = new SessionsClient();

        sessionsClient.getUserSessionsById(userId!, page).then(result => {
            setSessions(result.sessions ?? []);
            setTotalPages(result.totalPages!);
        }).catch(error => {
            console.error(error);
        });
    }, [userId, page]);


    const handlePageChange = (event: any, value: number) => {
        setPage(value - 1);
        const sessionsClient = new SessionsClient();
        sessionsClient.getUserSessionsById(userId!, value - 1).then(result => {
            setSessions(result.sessions ?? []);
            setTotalPages(result.totalPages!);
        }).catch(error => {
            console.error(error);
        });
    };

    if (user === undefined) {
        return (
            <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "50vh" }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container>
            <Paper sx={{ margin: "10px", padding: "10px" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={user.username} src={avatar} sx={{ width: 100, height: 100, margin: 2 }} />
                    <Typography variant="h3">{user.username}</Typography>
                </Box>
                <Button variant="contained" color="primary" sx={{ margin: "10px" }} component={RouterLink} to={"/user/" + user.username + "/live"}>Watch Live</Button>
                <Typography variant="h4">Sessions:</Typography>
                <List>
                    {sessions.map(session => (
                        <SessionRow key={session.id} session={session} showUser={false} format="compact" />
                    ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination count={totalPages + 1} color="primary" onChange={handlePageChange} size="large" />
                </Box>
            </Paper>
        </Container>
    );
}
