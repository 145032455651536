import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";



export default function FooterComponent() {
  return (
    <footer>
        <Box sx={{ width: "100vw", backgroundColor: "black", color: "white", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "1rem"}}
        >
          <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Link to="/privacy" style={{color:"white", textDecoration:"none", margin:5}}>Privacy Policy</Link>
            <Link to="/impressum" style={{color:"white", textDecoration:"none", margin:5}}>Legal Notice/Impressum</Link>
            <Link to="/tos" style={{color:"white", textDecoration:"none", margin:5}}>Terms of Service</Link>
          </Box>
            {/* <p>© 2024 - All rights reserved</p> */}
            <Typography variant="caption">
              {/* Track images are copyright of F1Laps. <br/> */}
            All assets are property of their respective owners </Typography>
        </Box>
    </footer>
  );
}